<template>
  <div class="edit-accommodation-type">
    <h1 class="admin-title">{{$t('Edit Accommodation Type')}}</h1>
    <ValidationObserver ref="validator">
      <form @submit.prevent>
        <b-row>
          <b-col>
            <RFValidation name="Name" rules="required">
              <RFInput label="Name" v-model="accommodationType.name"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation name="Title" rules="required">
              <RFInput label="Title" v-model="accommodationType.title"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation name="Sub Title" rules="required">
              <RFInput label="Sub Title" v-model="accommodationType.subtitle"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFMultipleInputAutocomplete label="Categories" v-model="accommodationTypeCategories" :occuped-list="accommodationType.categories" :list="categories" property="name"/>
            <transition name="fade">
              <div class="vee-error" v-if="showCategoriesError">{{categoriesErrorMessage}}</div>
            </transition>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="auto">
            <RFValidation name="Shared" rules="required">
              <RFCheckbox class="mt-4" label="Is Shared" v-model="accommodationType.isShared"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row class="justify-content-end">
          <b-col cols="auto">
            <RFButton class="mt-3" label="Save" background="#5CB85C" v-if="!loading" @on-click="save"/>
            <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import RFValidation from '@/components/forms/RFValidation'
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import RFCheckbox from '@/components/forms/RFCheckbox'
import RFMultipleInputAutocomplete from '@/components/forms/RFMultipleInputAutocomplete'
import { mapState } from 'vuex'

import { accommodationTypeService } from '@/services/admin'

export default {
  name: 'AdminAddAccommodationType',
  components: {
    RFValidation,
    RFInput,
    RFButton,
    RFLoader,
    RFCheckbox,
    RFMultipleInputAutocomplete,
  },
  data() {
    return {
      accommodationType: null,
      loading: false,
      accommodationTypeCategories:[],
      showCategoriesError: false,
      categoriesErrorMessage: 'Categories is required'
    }
  },
  computed: {
    ...mapState({
      storedAccommodationType: state => state.accommodationTypeStore.accommodationType,
      categories: state => state.categoryStore.categories,
    })
  },
  beforeMount() {
    this.accommodationType = { ...this.storedAccommodationType }
  },
  watch: {
    accommodationTypeCategories: {
      deep: true,
      handler() {
        this.accommodationType.categories = this.accommodationTypeCategories
        if(this.showCategoriesError && this.accommodationType.categories.length!==0){
          this.showCategoriesError = false
        }
      }
    },
  },
  methods: {
    async save() {
      const isValid = await this.$refs.validator.validate()

      if(this.accommodationType.categories.length===0){
        this.showCategoriesError = true
      }
      else if (isValid) {
        this.loading = true

        const { status } = await accommodationTypeService.editAccommodationType(this.accommodationType)

        if (status === 200) {
          this.$toasted.success(this.$i18n.t(`Room type successfully edited!`))
          this.$router.push({ name: 'accommodation-type-list' })
        } else {
          this.$toasted.error(this.$i18n.t(`Error during edit room type`))
        }

        this.loading = false
      }
    }
  }
}
</script>

<style>

</style>
